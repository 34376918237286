import React, { useEffect } from 'react';
import Layout, { Content } from '../../components/Layout';
import Navigation from '../../components/mybunac/navigation';
import { useAuth } from '../../hooks/useAuth';
import { useApplication } from '../../hooks/useApplication';
import Form from '../../components/mybunac/Application';
import styled from 'styled-components';

const ContentLayout = styled.div`
  display: block;
  height: 100%;
`;

const Application = ({ data, pageContext }) => {
  const { isAuthenticated } = useAuth();
  const { setApplicationId } = useApplication();

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    const applicationId = window.location.hash.substr(1);
    setApplicationId(applicationId);

    // eslint-disable-next-line
  }, [isAuthenticated]);

  return (
    <Layout title={'Application'}>
      <Navigation />
      <Content>
        <ContentLayout>
          <Form />
        </ContentLayout>
      </Content>
    </Layout>
  );
};

export default Application;